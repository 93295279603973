/*!
 * Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

:root {
  .mat-select-panel {
    @apply rounded shadow p-2;
  }

  .mat-option {
    @apply rounded text-sm font-medium h-10 transition duration-100 ease-out;

    &:hover, &.mat-active {
      @apply bg-primary/10;

      .mat-icon {
        @apply text-primary;
      }
    }

    .mat-icon {
      @apply transition duration-100 ease-out;
      font-size: 1.25rem;
      height: 1.25rem;
      width: 1.25rem;
    }

    &.mat-selected:not(.mat-option-multiple) {
      @apply bg-primary/20;
    }
  }

  .mat-menu-item-submenu-trigger {
    @apply pr-12;
  }
}
