/*!
 * Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

@use '@angular/material' as mat;
@import "../../var";

// @formatter:off
:root {
  // Generic
  --padding-gutter: 1rem;
  --padding: 1.5rem;
  --padding-16: 1rem;
  --padding-12: 0.75rem;
  --padding-8: 0.5rem;
  --padding-6: 0.375rem;
  --padding-4: 0.25rem;

  --color-primary: 64, 221, 126;
  --color-primary-hover: 88, 231, 136;
  --color-primary-active: 41, 204, 121;
  --color-primary-contrast: 255, 255, 255;

  --color-accent: 87, 89, 115;
  --color-accent-hover: 110, 113, 145;
  --color-accent-active: 68, 66, 89;
  --color-accent-contrast: 255, 255, 255;

  --color-warn: 214, 19, 22;
  --color-warn-hover: 193, 39, 45;
  --color-warn-active: 160, 2, 8;
  --color-warn-contrast: 255, 255, 255;

  @screen lg {
    --padding-gutter: 1.5rem;
  }

  // Typography
  --font: theme('fontFamily.sans');
  --font-weight-medium: 600;
  --font-caption: #{mat.font-weight($config, caption) #{mat.font-size($config, caption)}/#{mat.line-height($config, caption)} mat.font-family($config, caption)};
  --font-body-1: #{mat.font-weight($config, body-1) #{mat.font-size($config, body-1)}/#{mat.line-height($config, body-1)} mat.font-family($config, body-1)};
  --font-body-2: #{mat.font-weight($config, body-2) #{mat.font-size($config, body-2)}/#{mat.line-height($config, body-2)} mat.font-family($config, body-2)};
  --font-subheading-1: #{mat.font-weight($config, subheading-1) #{mat.font-size($config, subheading-1)}/#{mat.line-height($config, subheading-1)} mat.font-family($config, subheading-1)};
  --font-subheading-2: #{mat.font-weight($config, subheading-2) #{mat.font-size($config, subheading-2)}/#{mat.line-height($config, subheading-2)} mat.font-family($config, subheading-2)};
  --font-headline: #{mat.font-weight($config, headline) #{mat.font-size($config, headline)}/#{mat.line-height($config, headline)} mat.font-family($config, headline)};
  --font-title: #{mat.font-weight($config, title) #{mat.font-size($config, title)}/#{mat.line-height($config, title)} mat.font-family($config, title)};
  --font-display-1: #{mat.font-weight($config, display-1) #{mat.font-size($config, display-1)}/#{mat.line-height($config, display-1)} mat.font-family($config, display-1)};
  --font-display-2: #{mat.font-weight($config, display-2) #{mat.font-size($config, display-2)}/#{mat.line-height($config, display-2)} mat.font-family($config, display-2)};
  --font-display-3: #{mat.font-weight($config, display-3) #{mat.font-size($config, display-3)}/#{mat.line-height($config, display-3)} mat.font-family($config, display-3)};
  --font-display-4: #{mat.font-weight($config, display-4) #{mat.font-size($config, display-4)}/#{mat.line-height($config, display-4)} mat.font-family($config, display-4)};

  // Transitions
  --trans-ease-in-out: all var(--trans-ease-in-out-duration) var(--trans-ease-in-out-timing-function);
  --trans-ease-in-out-duration: #{$swift-ease-in-out-duration};
  --trans-ease-in-out-timing-function: #{$swift-ease-in-out-timing-function};
  --trans-ease-out: all var(--trans-ease-out-duration) var(--trans-ease-out-timing-function);
  --trans-ease-out-duration: #{$swift-ease-out-duration};
  --trans-ease-out-timing-function: #{$swift-ease-out-timing-function};
  --trans-ease-in: all var(--trans-ease-in-duration) var(--trans-ease-in-timing-function);
  --trans-ease-in-duration: #{$swift-ease-in-duration};
  --trans-ease-in-timing-function: #{$swift-ease-in-timing-function};
  --trans-shadow-duration: 280ms;
  --trans-shadow-timing-function: #{cubic-bezier(0.4, 0, 0.2, 1)};
  --trans-shadow: box-shadow var(--trans-shadow-duration) var(--trans-shadow-timing-function);
  --trans-cubic-bezier: all var(--trans-cubic-bezier-duration) var(--trans-cubic-bezier-timing-function);
  --trans-cubic-bezier-duration: 400ms;
  --trans-cubic-bezier-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);

  --text-color: #{$dark-primary-text};
  --text-color-light: #{$light-primary-text};
  --text-secondary: #{$dark-secondary-text};
  --text-secondary-light: #{$light-secondary-text};
  --text-hint: #{$dark-disabled-text};
  --text-hint-light: #{$light-disabled-text};

  // Foreground
  --foreground-divider: #{map-get(map-get($ngx-pids-theme, foreground), divider)};

  // Background
  --background-base: rgb(245, 245, 248);
  --background-foreground: #{map-get(map-get($ngx-pids-theme, background), card)};
  --background-foreground-rgb: #{red(map-get(map-get($ngx-pids-theme, background), card))}, #{green(map-get(map-get($ngx-pids-theme, background), card))}, #{blue(map-get(map-get($ngx-pids-theme, background), card))};
  --background-app-bar: #{map-get(map-get($ngx-pids-theme, background), app-bar)};
  --background-hover: #{map-get(map-get($ngx-pids-theme, background), hover)};

  // Elevation
  --elevation-default: var(--elevation-z6);
  --elevation-z0: none;
  --elevation-z1: #{ngx-pids-elevation(1)};
  --elevation-z2: #{ngx-pids-elevation(2)};
  --elevation-z3: #{ngx-pids-elevation(3)};
  --elevation-z4: #{ngx-pids-elevation(4)};
  --elevation-z5: #{ngx-pids-elevation(5)};
  --elevation-z6: #{ngx-pids-elevation(6)};
  --elevation-z7: #{ngx-pids-elevation(7)};
  --elevation-z8: #{ngx-pids-elevation(8)};
  --elevation-z9: #{ngx-pids-elevation(9)};
  --elevation-z10: #{ngx-pids-elevation(10)};
  --elevation-z11: #{ngx-pids-elevation(11)};
  --elevation-z12: #{ngx-pids-elevation(12)};
  --elevation-z13: #{ngx-pids-elevation(13)};
  --elevation-z14: #{ngx-pids-elevation(14)};
  --elevation-z15: #{ngx-pids-elevation(15)};
  --elevation-z16: #{ngx-pids-elevation(16)};
  --elevation-z17: #{ngx-pids-elevation(17)};
  --elevation-z18: #{ngx-pids-elevation(18)};
  --elevation-z19: #{ngx-pids-elevation(19)};
  --elevation-z20: #{ngx-pids-elevation(20)};

  // Sidenav
  --sidenav-width: 280px;
  --sidenav-collapsed-width: 72px;
  --sidenav-background: #{$sidenav-background};
  --sidenav-color: white;

  --sidenav-toolbar-background: #{darken($sidenav-background, 1.5)};
  --sidenav-toolbar-background-rgb: #{red(darken($sidenav-background, 1.5))}, #{green(darken($sidenav-background, 1.5))}, #{blue(darken($sidenav-background, 1.5))};
  --sidenav-section-divider-color: #{map-get(map-get($ngx-pids-dark-theme, foreground), divider)};

  // Sidenav Item
  --sidenav-item-min-height: 48px;
  --sidenav-item-padding-vertical: var(--padding-8);
  --sidenav-item-padding-horizontal: var(--padding);
  --sidenav-item-background-hover: #{darken($sidenav-background, 3)};
  --sidenav-item-background-active: #{darken($sidenav-background, 3)};
  --sidenav-item-font-weight: 500;
  --sidenav-item-color: #A1A2B6;
  --sidenav-item-color-hover: #{$light-primary-text};
  --sidenav-item-color-active: #{$light-primary-text};
  --sidenav-item-icon-color: #494B74;
  --sidenav-item-icon-color-hover: rgb(var(--color-primary));
  --sidenav-item-icon-color-active: rgb(var(--color-primary));
  --sidenav-item-icon-gap: 16px;
  --sidenav-item-icon-size: 24px;
  --sidenav-item-dropdown-background: #{darken($sidenav-background, 3)};
  --sidenav-item-dropdown-background-hover: #{darken($sidenav-background, 4)};
  --sidenav-item-dropdown-color-hover: var(--sidenav-item-color-hover);
  --sidenav-item-dropdown-background-active: var(--sidenav-item-dropdown-background-hover);
  --sidenav-item-dropdown-color-active: var(--sidenav-item-color-active);
  --sidenav-item-ripple-color: #{rgb(map-get(map-get($ngx-pids-theme, foreground), base), 0.1)};

  --sidenav-item-dropdown-gap: 12px;
  --sidenav-subheading-color: #6b6e7f;

  // Toolbar
  --toolbar-height: 64px;
  --toolbar-background: white;
  --toolbar-color: #{$dark-primary-text};
  --toolbar-icon-color: rgb(var(--color-primary));

  // Secondary Toolbar
  --secondary-toolbar-background: var(--background-foreground);
  --secondary-toolbar-height: 54px;

  // Navigation
  --navigation-height: 64px;
  --navigation-background: var(--background-foreground);
  --navigation-color: var(--text-secondary);

  // Footer
  --footer-height: 56px;
  --footer-z-index: 100;
  --footer-background: var(--background-foreground);
  --footer-color: var(--text-color);
  --footer-elevation: 0 -10px 30px 0 rgba(82, 63, 104, .06);

  // Page Layouts
  --page-layout-header-height: 200px;
  --page-layout-toolbar-height: 64px;

  // Misc
  --blink-scrollbar-width: 12px;
  --default-icon-size: 24px;
  --border-radius: 0.25rem;
}

// @formatter:on
