/*!
 * Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

@import "tailwindcss/base";
@import "./_base.scss";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "./_utilities.scss";
