/*!
 * Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

:root {
  .mat-button, .mat-stroked-button, .mat-flat-button, .mat-raised-button {
    @apply rounded-button min-w-[6rem];
    line-height: 2.5rem;
    box-shadow: none;
  }

  .mat-button-toggle-group:not(.mat-button-toggle-vertical) {
    @apply rounded-button;

    .mat-button-toggle:first-child .mat-button-toggle-label-content {
      @apply pl-4;
    }

    .mat-button-toggle:last-child .mat-button-toggle-label-content {
      @apply pr-4;
    }
  }

  .mat-button-toggle-button {
    @apply font-medium;
  }

  .mat-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
    box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.08);
    filter: drop-shadow(0 -1px 8px rgba(0, 0, 0, 0.04));
  }

  .mat-flat-button, .mat-raised-button, .mat-fab, .mat-mini-fab {
    transition: background-color var(--trans-cubic-bezier-duration) var(--trans-cubic-bezier-timing-function),
    color var(--trans-cubic-bezier-duration) var(--trans-cubic-bezier-timing-function),
    box-shadow var(--trans-cubic-bezier-duration) var(--trans-cubic-bezier-timing-function);

    &.mat-primary {
      &:hover {
        background-color: rgb(var(--color-primary-hover));
      }

      &.cdk-focused {
        background-color: rgb(var(--color-primary-active));
      }
    }

    &.mat-accent {
      &:hover {
        background-color: rgb(var(--color-accent-hover));
      }

      &.cdk-focused {
        background-color: rgb(var(--color-accent-active));
      }
    }

    &.mat-warn {
      &:hover {
        background-color: rgb(var(--color-warn-hover));
      }

      &.cdk-focused {
        background-color: rgb(var(--color-warn-active));
      }
    }
  }
}
