/*!
 * Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

@use '@angular/material' as mat;
@import "@angular/material/theming";

// Previously "$sidenav-background"
$sidenav-background: #1a202e !default;

$config: mat.define-typography-config(
  $font-family: var(--font),
  $display-4: mat.define-typography-level(56px, 1.5, 400, $letter-spacing: 0),
  $display-3: mat.define-typography-level(48px, 1.5, 400, $letter-spacing: 0),
  $display-2: mat.define-typography-level(40px, 1.5, 400, $letter-spacing: 0),
  $display-1: mat.define-typography-level(34px, 1.5, 400),
  $headline: mat.define-typography-level(24px, 1.5, 400, $letter-spacing: 0),
  $title: mat.define-typography-level(20px, 1.5, 600, $letter-spacing: 0),
  $subheading-2: mat.define-typography-level(18px, 1.5, 400, $letter-spacing: 0),
  $subheading-1: mat.define-typography-level(16px, 1.5, 400, $letter-spacing: 0),
  $body-2: mat.define-typography-level(14px, 1.5, 600, $letter-spacing: 0),
  $body-1: mat.define-typography-level(14px, 1.5, 400, $letter-spacing: 0),
  $caption: mat.define-typography-level(12px, 1.5, 400, $letter-spacing: 0),
  $button: mat.define-typography-level(14px, 1.5, 600, $letter-spacing: 0),
  $input: mat.define-typography-level(14px, 1.5, 400, $letter-spacing: 0)
) !default;

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$ngx-pids-primary: mat.define-palette((
  100: rgba(var(--color-primary), 0.1),
  500: rgba(var(--color-primary), 1),
  700: rgba(var(--color-primary), 1),
  contrast: (
    100: rgba(var(--color-primary-contrast), 1),
    500: rgba(var(--color-primary-contrast), 1),
    700: rgba(var(--color-primary-contrast), 1),
  )
)) !default;

$ngx-pids-accent: mat.define-palette((
  100: rgba(var(--color-accent), 0.1),
  500: rgba(var(--color-accent), 1),
  700: rgba(var(--color-accent), 1),
  contrast: (
    100: rgba(var(--color-accent-contrast), 1),
    500: rgba(var(--color-accent-contrast), 1),
    700: rgba(var(--color-accent-contrast), 1),
  )
)) !default;

// The warn palette is optional (defaults to red).
$ngx-pids-warn: mat.define-palette((
  100: rgba(var(--color-warn), 0.1),
  500: rgba(var(--color-warn), 1),
  700: rgba(var(--color-warn), 1),
  contrast: (
    100: rgba(var(--color-warn-contrast), 1),
    500: rgba(var(--color-warn-contrast), 1),
    700: rgba(var(--color-warn-contrast), 1),
  )
)) !default;

$ngx-pids-theme-foreground: (
  text: black,
  elevation: #000,
  divider: rgba(82, 63, 105, 0.06)
) !default;

$ngx-pids-theme-background: (
  app-bar: #ebebee
) !default;

// Create the theme object (a Sass map containing all of the palettes).
$ngx-pids-theme: (
  primary: $ngx-pids-primary,
  accent: $ngx-pids-accent,
  warn: $ngx-pids-warn,
  is-dark: false,
  foreground: map_merge(mat.$light-theme-foreground-palette, $ngx-pids-theme-foreground),
  background: map_merge(mat.$light-theme-background-palette, $ngx-pids-theme-background),
) !default;

$ngx-pids-dark-theme-background: (
  background: lighten($sidenav-background, 5),
  card: $sidenav-background,
  app-bar: darken($sidenav-background, 5),
  dialog: $sidenav-background,
  status-bar: darken($sidenav-background, 5)
) !default;

$ngx-pids-dark-theme: (
  primary: $ngx-pids-primary,
  accent: $ngx-pids-accent,
  warn: $ngx-pids-warn,
  is-dark: true,
  foreground: mat.$dark-theme-foreground-palette,
  background: map_merge(mat.$dark-theme-background-palette, $ngx-pids-dark-theme-background),
) !default;

$light-primary-text: rgba(247, 247, 252, 1);
$light-secondary-text: rgba(217, 219, 233, 1);
$light-disabled-text: rgba(87, 89, 115, 1);

$dark-primary-text: rgba(49, 56, 92, 1);
$dark-secondary-text: rgba(87, 89, 115, 1);
$dark-disabled-text: rgba(68, 66, 89, 1);
