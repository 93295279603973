/*!
 * Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

.apexcharts-legend-text {
  margin-left: 4px;
  top: -1px;
}

body .apexcharts-tooltip.light {
  background: var(--background-foreground);
}
