/*!
 * Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

.ngx-pids-layout-petapp {
  // Workaround: CSS calc() functions can't deal with values without a unit (e.g. 0 instead of 0px), when this is 0, SCSS or PostCSS make it a 0 instead of 0px, breaking the layout.
  --navigation-height: 0.00001px;

  &.ngx-pids-style-default {
    --toolbar-background: #{$sidenav-background};
    --navigation-color: white;
    --toolbar-color: white;
  }
}
