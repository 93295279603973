/*!
 * Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

// Horizontal Light Layout with dark sidenav and light toolbar

.ngx-pids-layout-pids {

}
