/*!
 * Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

:root {
  .mat-form-field-appearance-fill {
    &.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
    &.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
      transform: translateY(-0.875em) scale(calc(12 / 14));
    }

    &:not(.mat-form-field-disabled) {
      .mat-form-field-flex {
        &:hover {
          background-color: rgba(217, 219, 233, 1);
        }
      }
    }

    &.mat-form-field-disabled {
      .mat-form-field-flex {
        background-color: rgba(245, 245, 250, 1);
      }
    }

    .mat-form-field-flex {
      border-radius: 1em;
      padding: .5em 1em 0 1em;
      background-color: rgba(236, 238, 246, 1);
      transition: background-color var(--trans-cubic-bezier-duration) var(--trans-cubic-bezier-timing-function);

      &:hover {
        ~ .mat-form-field-underline {
          display: none;
        }
      }

    }

    .mat-form-field-infix {
      padding: 0.25em 0 0.5em 0;
    }

    .mat-form-field-underline {
      display: none;
    }
  }

  .mat-form-field-infix {
    border-top: 1.125em solid transparent;
  }

  input.mat-input-element {
    margin-top: 0;
    line-height: 22.75px;
  }
  .mat-input-element {
    caret-color: unset;
  }

  .mat-form-field-label:not(.mat-form-field-empty) {
    transition: color var(--trans-cubic-bezier-duration) var(--trans-cubic-bezier-timing-function);
  }

  .mat-form-field.mat-focused .mat-form-field-label {
    color: unset;
  }

}
