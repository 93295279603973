/*!
 * Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

@import "~simplebar/dist/simplebar.css";
@import "~highlight.js/styles/androidstudio.css";
// NgxPiDS Core
@import "@pitagon/ngx-pids/assets/styles/core";

/*
  You can override any CSS Variable or style here
  Example:

  :root {
   --sidenav-background: #333333;
  }

  All possible variables can be found in @pitagon/ngx-pids/assets/styles/core or by simply inspecting the element
   you want to change in Chrome/Firefox DevTools
*/

/**
  Uncomment the below code and adjust the values to fit your colors, the application will automatically adjust
 */
:root {
  // --color-primary: blue;
  // --color-primary-contrast: white;

  // --color-accent: yellow;
  // --color-accent-contrast: black;

  // --color-warn: yellow;
  // --color-warn-contrast: black;
}

.pi-mor-border span {
  background: rgba(0, 0, 0, 0.04);
  padding: 12px;
  border-radius: 25px;
  margin: auto;
  background: white;
}

.pi-mor-border span {
  cursor: pointer;
}

.label-table-cell {
  color: #575973 !important;
}

.color-work-status-on {
  color: rgb(var(--color-primary));
}
.color-work-status-off {
  color: rgb(var(--color-warn));
}
.pi-dot-circle {
  height: 15px;
  width: 15px;
  border-radius: 50%;
}

h4 {
  font-size: 14px !important;
  font-weight: 600 !important;
}

h3 {
  font-size: 16px !important;
  font-weight: 600 !important;
}

h2 {
  font-size: 18px !important;
  font-weight: 600 !important;
}

.input .mat-form-field-wrapper{
  max-height: 50px;
}

.btn-label {
  cursor: pointer;
}
