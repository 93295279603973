/*!
 * Copyright (C) 2022 Pitagon., JSC. - All Rights Reserved.
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

@font-face {
  font-family: 'Mulish var';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url('/assets/fonts/Mulish-VariableFont_wght.ttf') format('woff2');
  font-named-instance: 'Regular';
}

@font-face {
  font-family: 'Mulish var';
  font-style: italic;
  font-weight: 100 900;
  font-display: swap;
  src: url('/assets/fonts/Mulish-Italic-VariableFont_wght.ttf') format('woff2');
  font-named-instance: 'Italic';
}
